import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import Axios from 'axios';
import LS_SERVICE from './utils/localStorage'
import { ADMIN_PATIENT_LINK } from './constant';
import '../src/HomecareScreens/scss/newhomecare.scss'
// import { Provider } from 'react-redux';
// import ErrorBoundary from './ErrorBoundary';
// import { authenticationService } from '../src/authorize/_services';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js';
//import {JqueryMenu} from './assets/js/ThemeFunctions';
import './assets/scss/style.scss';
import './assets/scss/ipd/index.scss';
import './assets/scss/ipd/patient_chart_summary.css';
import './assets/scss/ipd/medicine.scss';
import './assets/scss/ipd/patient-history.scss';
import './index.css';
import Store from './Store';
import { Provider } from 'react-redux';

const AppComponent = React.lazy(() => import('./App'));
// const MenuComponent = React.lazy(() => import('./menu'));
Axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (res) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // return Promise.reject(error);
  if (res?.response?.status === 401 || (res.status === 400 && LS_SERVICE.get('HEADER_TOKEN') === '')) {
      // (res?.response?.status === 400 && LS_SERVICE.has('loginMobile') === false)) {
     LS_SERVICE.clear();
    //headerService$.showHeader({ showHeader: 'false' });
   window.location.replace(ADMIN_PATIENT_LINK+"login"); 
  }
  return Promise.reject(res);
});

ReactDOM.render(
    <>
    {/* {authenticationService.currentUserValue ? '' : window.location.href = '/signin'} */}
    <Provider store={Store}>
      <BrowserRouter>
        <Suspense fallback={
          <main>
            <div style={{ width: '100%', height: '100vh', background: 'rgba(255,255,255,.7)', borderRadius: '4px', overflow: "hidden", position: "absolute", zIndex: '999999' }} className="d-flex flex-column align-items-center justify-content-center">
              <img src='https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif' width='70' />
              <p><strong>Loading..</strong></p>
            </div>
          </main>
        }>
          {/* {authenticationService.currentUserValue ? <div></div>: <div style={{ width: '100vw', height: '100vh' }}></div>} */}

          {/* <ErrorBoundary> */}
            {/* <ClearBrowserCacheBoundary auto={true} fallback='Clearing Browser Cache Loading..' duration={60000}>
             
            </ClearBrowserCacheBoundary> */}

            {/* <ClearCache> */}
              <AppComponent />
            {/* </ClearCache> */}
          {/* </ErrorBoundary> */}
        </Suspense>


      </BrowserRouter>

    </Provider>
    </>
  

  , document.getElementById('root')
);

// serviceworker.unregister();
