// add by akram saif
export const HC_API_URL = "https://uat.rfh.apim.myhealthcare.co/"
export const HC_PINCODE_URL = "https://uat.rfh.api.myhealthcare.co/"
export const SAIL_BACKEND_SPA = "https://uat.rfh.spapi.myhealthcare.co/"
export const API_BASE_URL_EMR = "https://uat.rfh.emr.myhealthcare.co/"
export const ADMIN_PATIENT_LINK = `https://uat.rfh.hp.myhealthcare.co/`
export const API_BASE_URL = `${HC_API_URL}api/homecare/`;

export const EMR_API_TOKEN = "OzxOcSjaMtXpuqEGJhsPDx8UDW8Is0v6oQKqyTobaaouoTYWYgzsExc7h8EzYZva"
export const S3_BASE_URL = "https://qb-innocirc-backups.s3.ap-south-1.amazonaws.com/"
export let Loader = "https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif"

export const HC_AGGREGATOR_TYPE = "opd_emr"
export const HC_API_TOKEN = "eyJpdiI6ImxUeXIra0VIMDdrb1hSY1F3Z2UyXC9nPT0iLCJ2YWx1ZSI6Imh1TmZDWWdLRTh2cVBVTHdrR3A4dkE9PSIsIm1hYyI6IjdjNzAxMTY4OTRkNGExYmMzN2U5N2VmZDZlNTQwY2I5YmNjYjU2ZTAwYThhZjBkNzk5YWMwM2Y1YTJiZGM3MjgifQ=="

/*------------------ all teams constants start ------------------*/
export const HC_TEAM_UPDATE_API_URL = `${API_BASE_URL}team-update`
export const HC_TEAM_DELETE_API_URL = `${API_BASE_URL}team-delete`
export const HC_TEAM_EDIT_API_URL = `${API_BASE_URL}team-edit`
export const HC_TEAM_SAVE_API_URL = `${API_BASE_URL}team-store`
export const HC_TEAM_LIST_API_URL = `${API_BASE_URL}team-list`
export const HC_TEAM_LIST_REQUEST = "HC_TEAM_LIST_REQUEST"
export const HC_TEAM_LIST_SUCCESS = "HC_TEAM_LIST_SUCCESS"
export const HC_TEAM_LIST_FILTER = "HC_TEAM_LIST_FILTER"
export const HC_TEAM_LIST_FAIL = "HC_TEAM_LIST_FAIL"
/*------------------ all teams constants end   ------------------*/


/*------------------ all extended hospital location start   ------------------*/
export const HC_HOSPITAL_LOCATION_LIST_API_URL = `${API_BASE_URL}hospital-locations-list`
export const HC_HOSPITAL_LOCATION_SAVE_API_URL = `${API_BASE_URL}store-extended-hospital-locations`
export const HC_HOSPITAL_LOCATION_EDIT_API_URL = `${API_BASE_URL}hospital-location-edit`
export const HC_HOSPITAL_LOCATION_UPDATE_API_URL = `${API_BASE_URL}update-hospital-location`
export const HC_HOSPITAL_LOCATION_LIST_REQUEST = "HC_HOSPITAL_LOCATION_LIST_REQUEST"
export const HC_HOSPITAL_LOCATION_LIST_SUCCESS = "HC_HOSPITAL_LOCATION_LIST_SUCCESS"
export const HC_HOSPITAL_LOCATION_LIST_FILTER = "HC_HOSPITAL_LOCATION_LIST_FILTER"
export const HC_HOSPITAL_LOCATION_LIST_FAIL = "HC_HOSPITAL_LOCATION_LIST_FAIL"
/*------------------ all extended hospital location end   ------------------*/


/*------------------ all team resource start   ------------------*/
export const HC_TEAM_RESOURCE_CAT_LIST_API_URL = `${API_BASE_URL}get-resource-name-list`
export const HC_TEAM_RESOURCE_LIST_API_URL = `${API_BASE_URL}homecare-resources-list`
export const HC_TEAM_RESOURCE_LIST_REQUEST = "HC_TEAM_RESOURCE_LIST_REQUEST"
export const HC_TEAM_RESOURCE_LIST_SUCCESS = "HC_TEAM_RESOURCE_LIST_SUCCESS"
export const HC_TEAM_RESOURCE_LIST_FILTER = "HC_TEAM_RESOURCE_LIST_FILTER"
export const HC_TEAM_RESOURCE_LIST_FAIL = "HC_TEAM_RESOURCE_LIST_FAIL"
/*------------------ all team resource end   ------------------*/


/*------------------ all sub category start   ------------------*/
export const HC_SUB_CATEGORY_LIST_API_URL = `${API_BASE_URL}subcategory-list`
export const HC_SUB_CATEGORY_LIST_REQUEST = "HC_SUB_CATEGORY_LIST_REQUEST"
export const HC_SUB_CATEGORY_LIST_SUCCESS = "HC_SUB_CATEGORY_LIST_SUCCESS"
export const HC_SUB_CATEGORY_LIST_FILTER = "HC_SUB_CATEGORY_LIST_FILTER"
export const HC_SUB_CATEGORY_LIST_FAIL = "HC_SUB_CATEGORY_LIST_FAIL"
/*------------------ all sub category end   ------------------*/

/*------------------ all service & routing mapping start   ------------------*/
export const HC_ROUTE_MAPING_LIST_API_URL = `${API_BASE_URL}route-list?search=`
export const HC_ROUTE_MAPING_SAVE_API_URL = `${API_BASE_URL}store-route`
export const HC_ROUTE_MAPING_EDIT_API_URL = `${API_BASE_URL}get-route`
export const HC_ROUTE_MAPING_UPDATE_API_URL = `${API_BASE_URL}update-route`
/*------------------ all service & routing mapping end   ------------------*/

/*-------------------------- all country start   --------------------------*/
export const HC_COUNTRY_LIST_API_URL = `${API_BASE_URL}country-list`
export const HC_COUNTRY_LIST_REQUEST = "HC_COUNTRY_LIST_REQUEST"
export const HC_COUNTRY_LIST_SUCCESS = "HC_COUNTRY_LIST_SUCCESS"
export const HC_COUNTRY_LIST_FILTER = "HC_COUNTRY_LIST_FILTER"
export const HC_COUNTRY_LIST_FAIL = "HC_COUNTRY_LIST_FAIL"
/*-------------------------- all country end   --------------------------*/

/*-------------------------- all state start   --------------------------*/
export const HC_STATE_LIST_API_URL = `${API_BASE_URL}state-list`
export const HC_STATE_LIST_REQUEST = "HC_STATE_LIST_REQUEST"
export const HC_STATE_LIST_SUCCESS = "HC_STATE_LIST_SUCCESS"
export const HC_STATE_LIST_FILTER = "HC_STATE_LIST_FILTER"
export const HC_STATE_LIST_FAIL = "HC_STATE_LIST_FAIL"
/*-------------------------- all state end   --------------------------*/

/*-------------------------- all city start   --------------------------*/
export const HC_CITY_LIST_API_URL = `${API_BASE_URL}city-list`
export const HC_CITY_LIST_REQUEST = "HC_CITY_LIST_REQUEST"
export const HC_CITY_LIST_SUCCESS = "HC_CITY_LIST_SUCCESS"
export const HC_CITY_LIST_FILTER = "HC_CITY_LIST_FILTER"
export const HC_CITY_LIST_FAIL = "HC_CITY_LIST_FAIL"
/*-------------------------- all city end   --------------------------*/

/*-------------------------- all pincode sta -------------------------*/
export const HC_PINCODE_LIST_API_URL = `${API_BASE_URL}pincode-list`
export const HC_PINCODE_LIST_REQUEST = "HC_PINCODE_LIST_REQUEST"
export const HC_PINCODE_LIST_SUCCESS = "HC_PINCODE_LIST_SUCCESS"
export const HC_PINCODE_LIST_FILTER = "HC_PINCODE_LIST_FILTER"
export const HC_PINCODE_LIST_FAIL = "HC_PINCODE_LIST_FAIL"
/*-------------------------- all pincode end--------------------------*/

/*-------------------------- hospital start --------------------------*/
export const HC_HOSPITAL_LIST_API_URL = `${API_BASE_URL}hospital-list`
export const HC_HOSPITAL_LIST_REQUEST = "HC_HOSPITAL_LIST_REQUEST"
export const HC_HOSPITAL_LIST_SUCCESS = "HC_HOSPITAL_LIST_SUCCESS"
export const HC_HOSPITAL_LIST_FILTER = "HC_HOSPITAL_LIST_FILTER"
export const HC_HOSPITAL_LIST_FAIL = "HC_HOSPITAL_LIST_FAIL"
/*-------------------------- hospital end  ---------------------------*/

/*-------------------------- category end  ---------------------------*/
export const HC_CATEGORY_LIST_API_URL = `${API_BASE_URL}category-list`
export const HC_CATEGORY_LIST_REQUEST = "HC_CATEGORY_LIST_REQUEST"
export const HC_CATEGORY_LIST_SUCCESS = "HC_CATEGORY_LIST_SUCCESS"
export const HC_CATEGORY_LIST_FILTER = "HC_CATEGORY_LIST_FILTER"
export const HC_CATEGORY_LIST_FAIL = "HC_CATEGORY_LIST_FAIL"
export const HC_ROUTE_MAPING_GET_SINGLE_CATEGORY = `${API_BASE_URL}get-category`
export const HC_UPDATE_CATEGORY = `${API_BASE_URL}update-category`
export const HC_STORE_CATEGORY = `${API_BASE_URL}store-category`
export const HC_TREATMENT_OU_API_URL = `${API_BASE_URL}homecare-treatment-ou-list`
export const HC_DEPARTMENT_OU_API_URL = `${API_BASE_URL}homecare-department-ou-list`
/*-------------------------- category end  ---------------------------*/

export const HC_SERVICE_CODE_API_URL = `${API_BASE_URL}homecare-service-code-list`
export const HC_CLINICAL_EVALUATION_EDIT_API = `${API_BASE_URL}booking/clinical-evaluation-edit`

/*********************************** Home Care Booking API's START ******************/

export const HC_LOGIN = `${HC_API_URL}api/login`
export const HC_LOGOUT = `${HC_API_URL}api/logout`
export const HC_BOOKING_LIST = `${HC_API_URL}api/homecare/manage-homecare-bookings`
export const HC_ROUTE_LIST = `${HC_API_URL}api/homecare/route-list`
export const HC_CATEGORY_LIST = `${HC_API_URL}api/homecare/route-list`
export const HC_RESOURCE_LIST = `${HC_API_URL}api/homecare/homecare-resources-list`
export const HC_PAY_LATER = `${HC_API_URL}api/homecare/pay-later`
export const HC_PAY_SEND_LINK = `${HC_API_URL}api/homecare/send-payment-link`
export const HC_SAVE_RESOURCE_ASSIGNED = `${HC_API_URL}api/homecare/booking/save-resource-assigned`
export const HC_SAVE_CLINICAL_EVAL = `${HC_API_URL}api/homecare/booking/save-clinical-evaluation`
export const HC_GET_ASSIGNED_DATA = `${HC_API_URL}api/homecare/booking/get-assigned-data`
export const HC_SAVE_RESOURCE_ASSIGNED_UPDATE = `${HC_API_URL}api/homecare/booking/resource-assigned-update`
export const HC_CARE_PLAN_DATA = `${HC_API_URL}api/homecare/get-homecare-booking-care-plan`
export const HC_CARE_PLAN_UPDATE = `${HC_API_URL}api/homecare/get-homecare-booking-care-plan-update`;
export const HC_CARE_PLAN_DETAILS = `${HC_API_URL}api/homecare/get-homecare-booking-care-plan-edit`
export const HC_TEAM_LIST = `${HC_API_URL}api/homecare/team-list`
export const CANCEL_HOMECARE_PLAN = `${HC_API_URL}api/homecare/cancel-homecare-booking`
export const UPLOAD_HOMECARE_DOCUMENT = `https://pphomehealthspapi.rfhospital.org/api/v1/spa/upload-booking-document`
export const GET_HOMECARE_DOCUMENT = `https://pphomehealthspapi.rfhospital.org/api/v1/spa/get-booking-document`
export const HC_PINCODE_STATE_CITY = `${HC_PINCODE_URL}api/patients/ios/v2/getdata-based-on-pincode`
export const HC_DOCTOR_LIST = `${HC_API_URL}api/homecare/booking/get-doctor-list`
export const HC_VISIT_TYPE_LIST = `${HC_API_URL}api/homecare/booking/get-visit-type`
export const HC_ORDER_MASTER_LIST = `${HC_API_URL}api/homecare/booking/get-order-master`
export const HC_DEPARTMENT_LIST = `${HC_API_URL}api/homecare/booking/get-department-ou`
export const HC_CHECK_RESOURCE_TEAM_ASSIGNABILITY = `${HC_API_URL}api/homecare/booking/check-resource-team-assignability`

export const TIME_SLOTS = [
	{ "time_slot": "09:00", "timeStr": "09:00 AM" },
	{ "time_slot": "10:00", "timeStr": "10:00 AM" },
	{ "time_slot": "11:00", "timeStr": "11:00 AM" },
	{ "time_slot": "12:00", "timeStr": "12:00 AM" },
	{ "time_slot": "13:00", "timeStr": "01:00 PM" },
	{ "time_slot": "14:00", "timeStr": "02:00 PM" },
	{ "time_slot": "15:00", "timeStr": "03:00 PM" },
	{ "time_slot": "16:00", "timeStr": "04:00 PM" },
	{ "time_slot": "17:00", "timeStr": "05:00 PM" },
	{ "time_slot": "18:00", "timeStr": "06:00 PM" },
	{ "time_slot": "19:00", "timeStr": "07:00 PM" },
	{ "time_slot": "20:00", "timeStr": "08:00 PM" },
	{ "time_slot": "21:00", "timeStr": "09:00 PM" },
]

export const PAYMENT_MODE = ['jiomoney', 'payu', 'cash', 'hdfc', 'vPay', 'not_applicable', 'cash_at_hospital', 'card', 'advance', 'upi', 'netbanking', 'other', 'pay_on_arrival', 'razorpay', 'credit', 'jiomoney', 'unknown']

/*********************************** Home Care Booking API's END ******************/

/*********************************** Home Manage Resources API's START ******************/
export const HC_MANAGE_RESOURCES_START = `${HC_API_URL}api/homecare/homecare-resources-list`
export const HC_MANAGE_RESOURCES_ADD = `${HC_API_URL}api/homecare/store-homecare-resources`
export const HC_MANAGE_RESOURCES_UPDATE = `${HC_API_URL}api/homecare/homecare-resources-update`
export const HC_MANAGE_RESOURCES_FETCH_BY_ID = `${HC_API_URL}api/homecare/homecare-resources-edit`
export const HC_MANAGE_RESOURCES_ATTENDANCE_FETCH = `${HC_API_URL}api/homecare-attendance-details`;
export const HC_MANAGE_RESOURCES_ATTENDANCE = `${HC_API_URL}api/homecare-attendance-summary-details`;
export const HC_ORDER_CARE_PLAN_DETAILS = `${HC_API_URL}api/homecare/get-homecare-booking-care-plan-history`;


/*********************************** Home Manage Resources API's END ******************/
/*----- Add Category---*/
export const HomeCareAdd_CATEGORY_REQUEST = "HomeCareAdd_CATEGORY_REQUEST"
export const HomeCareAdd_CATEGORY_SUCCESS = "HomeCareAdd_CATEGORY_SUCCESS"
export const HomeCareAdd_CATEGORY_FAIL = "HomeCareAdd_CATEGORY_FAIL"

/*----- Update Category---*/
export const HomeCareUpdate_CATEGORY_REQUEST = "HomeCareUpdate_CATEGORY_REQUEST"
export const HomeCareUpdate_CATEGORY_SUCCESS = "HomeCareUpdate_CATEGORY_SUCCESS"
export const HomeCareUpdate_CATEGORY_FAIL = "HomeCareUpdate_CATEGORY_FAIL"


export const HOSPITAL_LIST = "hospital-list";
export const CATEGORIES_LIST = "category-list";
export const HOSPITAL_LOCATIONS_LIST = "hospital-locations-list";
export const SUB_CATEGORY_LIST = "subcategory-list";
export const SAVE_HOMECARE_SERVICE = "store-homecare-service";
export const SAVE_HOMECARE_PACKAGE = "store-homecare-package";
export const UPDATE_HOMECARE_SERVICE = "homecare-service-update";
export const UPDATE_HOMECARE_PACKAGE = "homecare-package-update";
export const HOMECARE_SERVICE_LIST = `${HC_API_URL}api/homecare/homecare-service-list`;
export const HOMECARE_CHECK_PLAN = "check-homecare-plan";
export const VITALS_EMR_MASTER = `${API_BASE_URL_EMR}api/opdemr/patient-app/vitalgroup/list`;

export const schedulelistApi = `${HC_API_URL}api/homecare/scheduler-list`
export const categoriesListApi = `${HC_API_URL}api/homecare/category-list`
export const subCategoriesApi = `${HC_API_URL}api/homecare/get-category`
export const resourcesList = `${HC_API_URL}api/homecare/homecare-resources-list`
export const routeList = `${HC_API_URL}api/homecare/route-list`
export const storeScheduler = `${HC_API_URL}api/homecare/store-scheduler`
export const schedulerEdit = `${HC_API_URL}api/homecare/scheduler-edit`
export const schedulerDetailsByResourceId = `${HC_API_URL}api/homecare/scheduler-edit-resource-id`
export const schedulerUpdate = `${HC_API_URL}api/homecare/scheduler-update`
export const getCareplanList = `${HC_API_URL}api/homecare/homecare-plan-list`
export const getHospitalList = `${HC_API_URL}api/homecare/hospital-list`
export const taskFrequencyList = `${HC_API_URL}api/homecare/get-task-frequency-list`
export const homecareServiceList = `${HC_API_URL}api/homecare/homecare-service-package-list`
export const saveHomeCarePlan = `${HC_API_URL}api/homecare/save-homecare-plan`
export const updateHomeCarePlan = `${HC_API_URL}api/homecare/update-homecare-plan`
export const getHomeCarePlan = `${HC_API_URL}api/homecare/get-homecare-plan`
export const hospitalLocationApi = `${HC_API_URL}api/homecare/hospital-locations-list`
export const storeLeave = `${HC_API_URL}api/homecare/store-leave`
export const leaveList = `${HC_API_URL}api/homecare/leave-list`
export const leaveDelete = `${HC_API_URL}api/homecare/leave-delete`
export const leaveEdit = `${HC_API_URL}api/homecare/leave-edit`
export const updateLeave = `${HC_API_URL}api/homecare/leave-update`
//******************Homecare Packages related  */
export const saveHomeCareService = `${HC_API_URL}api/homecare/store-homecare-service`
export const getPacakageList = `${HC_API_URL}api/homecare/homecare-service-package-list`


//end of homecare packages

/*----- homecare resourece api---*/
export const homecareTaskList = `${HC_API_URL}api/homecare-task-details`
export const rejectedBookingList = `${HC_API_URL}api/homecare-rejected-booking`